import React from "react";
import parse from "html-react-parser";
import { graphql, Link } from "gatsby";
import { ArrowBack } from "@styled-icons/material/ArrowBack";
import { OrmsidePageQuery } from "../../graphql-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Options } from "@contentful/rich-text-react-renderer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { INLINES } from "@contentful/rich-text-types";
import * as S from "../styles/contentfulWebsiteContent.styles";
import theme from "../styles/theme";
import GlobalStyles from "../styles/GlobalStyles";
import { ThemeProvider } from "styled-components";
import Grid from "../components/Grid/Grid";
import HomeButton from "../components/HomeButton/HomeButton";
import SEO from "../components/SEO/SEO";

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="blank" rel="noreferrer">
        {children}
      </a>
    ),
  },
};

interface Props {
  data: OrmsidePageQuery;
}

const ProjectPage = ({ data }: Props) => {
  const pageData = data.page.nodes[0];
  let image;
  let mediaPlayer;

  const richText =
    pageData?.information?.raw &&
    renderRichText({ raw: pageData.information.raw, references: [] }, options);

  if (pageData.mediaOption) {
    // if embedded media player is main image on page just set mainPlayer
    mediaPlayer = pageData?.media?.media && pageData.media.media;
  } else {
    // embedded media player is not main image on page set both image and mediaPlayer
    image =
      pageData.image?.gatsbyImageData &&
      getImage(pageData.image?.gatsbyImageData);

    mediaPlayer = pageData?.media?.media && pageData.media.media;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <HomeButton />
      <S.Page pageType={pageData.type ?? "project"}>
        <S.Container>
          <S.Header>
            {pageData.type === "project" ? "Projects" : "Radio"}
          </S.Header>
          <Grid>
            <S.Image>
              {image ? (
                <GatsbyImage image={image} alt={pageData.title ?? "alt"} />
              ) : (
                parse(mediaPlayer ?? "")
              )}
            </S.Image>
            <S.Details>
              <S.Heading>{pageData.title}</S.Heading>
              <S.SubHeading>{pageData.artist}</S.SubHeading>
              <S.Body>{richText}</S.Body>
              {image ? parse(mediaPlayer ?? "") : null}
            </S.Details>
          </Grid>
          <S.BackButton>
            <Link to="/">
              <ArrowBack size="48" />
            </Link>
          </S.BackButton>
        </S.Container>
      </S.Page>
    </ThemeProvider>
  );
};

export default ProjectPage;

export const Head = ({ data }: Props) => (
  <SEO title={`Ormside - ${data.page.nodes[0].title}` ?? "Ormside Projects"} />
);

export const query = graphql`
  query OrmsidePage($id: String!) {
    page: allContentfulWebsiteContent(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        artist
        date
        type
        information {
          raw
        }
        image {
          gatsbyImageData(formats: [AUTO, WEBP])
        }
        media {
          media
        }
        mediaOption
      }
    }
  }
`;
